<template>


Hier muss die Pivot noc rein die alte oder gibt es da was schöneres?

Transactions

    <v-card>
      <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
        :items-per-page=40
        :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100]
        }"
        density="compact"
        @click:row="handleClickActivty"
        class="elevation-1 text-caption"
      >

        <template v-slot:item.stocks_transactions_datum="{ item }">
          <div v-text="new Date(item.stocks_transactions_datum).toLocaleDateString()"></div>
        </template>

      </v-data-table>
    </v-card>


</template>
<script>
import { mapGetters} from 'vuex';
  export default {
    data: () => ({
      search: '',
      headers: [
        { title: 'Typ',
            fixed: true,
            key: 'stocks_transactions_category',align: 'end'},
        {
          title: 'Menge',
          align: 'start',
          key: 'stocks_menge',
        },
        {
          title: 'Preis',
          align: 'start',
          key: 'stocks_transactions_preis',
        },
        {
          title: 'Datum',
          align: 'start',
          key: 'stocks_transactions_datum',
        },
        {
          title: 'Beschreibung',
          align: 'start',
          key: 'stocks_transactions_beschreibung',
        },
      ],
        pagination: {
          rowsPerPage: -1
        },


    }),
  mounted: function(){

    console.log('Bin in TransactionScreen')
    this.$store.dispatch('getTransactions').then(()=>{
        console.log("Got some data, now lets show something in this component",this.rankings)
        //refresh map


        }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })



  },
    methods: {
    openFile(name) {
        console.log('Open:',name)
        window.open(name);
      },

  },
    computed: {
    ...mapGetters({ transactions: 'stateTransactions'}),


    },
  }
</script>